@import "node_modules/bootstrap/scss/bootstrap";

:root {
    --colorMenu: #000000;
    --colorText: #000000;
    --color1: #c74040;
    --color2: #fff;
    --color3: #00aeff;

}


.body {
    color: var(--colorText);
}

.nav-menu-MCtext {
    text-decoration: solid;
    color: var(--color1);
}
/*Убираем прозрачность у карточки card*/
.border-light {
    --bs-border-opacity: 0;
}

/*Отступ у карточек*/
.card-body {
  padding: 5px;
}



/*Стиль векторных иконок */
  .bi_W {
    fill: #25d366;
  }
  .bi_T {
    fill: #0088cc;
  }
  .bi_Y {
    fill: #ff0000;
  }
  .bi_F {
    fill: #1877f2;
  }
  .bi_I {
    fill: #e1306c;
  }
  .bi_White {
    fill: #fff;
  }
  .bi_Gold {
    fill: #ffd900;
  }

  .bi_McRed {
    fill: #c74040;
  }

  .bi_McGreen {
    fill: #3be8b0
  }

  .bi_McOrange {
    fill: #ff9933
  }


  



/*Стиль текста*/
.McColorText{
    color: var(--colorText) !important;
}

.Text-white-mc {
    color: var(--color2);
}

.Text-blue-mc {
  color: var(--color3);
}


.Text-red-mc {
    color: var(--color1);
}

.text-NO-solis {
  font-weight: normal !important;
}

.text-solid {
  font-weight: bold !important;
}



/*Стиль ссылок*/
.LinkStyle {
    color: var(--color1);
    text-decoration:none;

}

.LinkStyle:hover {
    color: var(--color1);
}



/*Стиль кнопок слайдера*/
.btn_mc_slider:hover {
    background-color:var(--color1)
}


/*Стиль главного меню*/

.navbar-brand, .navbar-brand:hover {
    color: var(--color1);
    font-weight: bold;
   }

/*Стиль выпадающих вниз менюшек, цвет при наведении*/
.nav-link:hover, .nav-link:focus {
    color: var(--color1);
}

/*Стиль выпадающих вниз менюшек*/
.dropdown-item {
    /*font-weight: normal;*/
    font-size:larger
    /*color: var(--color1);*/
}

.dropdown-menu {
    border: none;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
    /*font-weight: normal;*/
}
/*Цвет главного меню*/
.dropdown-item:hover, .dropdown-item:focus {
    background-color:#fff;
    color:var(--color1)
}

/*Растояние между меню*/
.navbar {
  --bs-navbar-nav-link-padding-x: 0.8rem;
  --bs-navbar-brand-hover-color:var(--color1)
}


.dropdown-menu[data-bs-popper] {
    margin-top: -0.5rem;
}

/*Цвет главного меню*/
.navbar-nav .nav-link.active {
    color: var(--colorMenu);
}

/*подсветка главного меню при наводке*/
.navbar-nav .nav-link.active:hover {
    color: var(--color1);
}

.nav-link:hover, .nav-link:focus {
  color: var(--color1);
}

.navbar-toggler {
    color: var(--color1);
}

.navbar-nav {
  --bs-nav-link-color: var(--colorMenu)
  --bs-nav-link-hover-color:var(--color1)

}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: transparent;
}

.nav-link {
  color: #c74040;
}

/*Стиль карусели*/

.carousel-indicators [data-bs-target] {
    background-color: var(--color1);
}

.carousel-control-prev-icon {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c74040'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {

background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c74040'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-caption {
    color:var(--colorMenu) 
}


/*Стиль кнопки в слайдере и везде, разобраться со стилями*/
.btn-danger, .btn-danger:hover {
    color:#fff;
    background-color: var(--color1);
    border-color: var(--color1);
 }

 .bg-light {
    background-color:#fff;
 }

/*Стиль иконок снизу*/
 .btn-floating {
    border-radius:50%;
    padding: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
 }

 /* Стили для формы обратной связи. Начало */
  
 .form-success_hide {
    display: none;
  }

     
  .form-success__message {
    position: relative;
    padding: 1rem;
    background-color: #fafafa; 
  }
  
  .form-success__btn {
    font-weight: 400;
    color: var(--color1);
    border: none;
    background-color: transparent;
    display: inline;
    padding: 0; }

  /* Стили для сообщения об ошибках  */

 .form-error {
    position: relative;
    padding: .5rem 1rem 0.6rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    color: var(--color1);
    background-color: #dc35450d;
    border: 1px solid var(--color1);
  }
  
  .form-error_hide {
    display: none;
  }


  /* в карточках подтянули подписи */
.card-title {
  margin-top: -2px !important;
}


.card-text {
  margin-top: -8px !important;
}

  /* Цвета аккордиона */

  .accordion-button:not(.collapsed) {
    background-color: white;
  }

  .accordion-button:focus {
    border-color: var(--colorMenu) ;
    box-shadow: var(--colorMenu) ;
  }

  /* Отталкивание текста в карточке */

  .card-text {
    margin-left: 6px !important
  }
  .card-title {
    margin-left: 6px !important

  }










































